import React from 'react';
import styled from 'styled-components';
import Menu from '../Menu/Menu';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 80px); // Accounts for menu height
  padding: var(--spacing-xl);
  background-color: var(--color-secondary);
  text-align: center;
`;

const ContentWrapper = styled.div`
  max-width: 680px;
  margin: 0 auto;
`;

const Titulo = styled.h1`
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  color: var(--color-primary);
  font-size: var(--font-size-xxl);
  margin-bottom: var(--spacing-medium);
  
  @media (max-width: 768px) {
    font-size: var(--font-size-xl);
  }
`;

const Mensagem = styled.p`
  font-family: var(--font-family-text);
  font-weight: var(--font-weight-text-regular);
  color: var(--color-text);
  font-size: var(--font-size-lg);
  line-height: 1.6;
  
  @media (max-width: 768px) {
    font-size: var(--font-size-md);
  }
`;

const Destaque = styled.span`
  color: white;
  font-weight: var(--font-weight-text-bold);
`;

const SiteEmConstrucao = ({ pageName = "esta página" }) => {
  return (
    <>
      <Menu dark />
      <Container>
        <ContentWrapper>
          <Titulo>Em Breve!</Titulo>
          <Mensagem>
            Estamos finalizando <Destaque>{pageName}</Destaque> com todo cuidado. 
            Queremos oferecer a melhor experiência possível, por isso pedimos um 
            pouco mais de paciência. Acompanhe nossas 
            redes sociais para receber atualizações!
          </Mensagem>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default SiteEmConstrucao;