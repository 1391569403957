import React from 'react'
import Hero from '../../components/Hero/Hero'
// import AboutUs from '../../components/AboutUs/AboutUs'
import Segmentos from '../../components/Segmentos/Segmentos'
// import SlantedGridGallery from '../../components/Gallery/Gallery'
import Category from '../../components/Category/Category'
// import BlogSection from '../../components/BlogSection/BlogSection'
import gal1 from '../../assets/images/gallery/gal-1.jpeg'
import gal2 from '../../assets/images/gallery/gal-2.jpeg'
import gal3 from '../../assets/images/gallery/gal-3.jpeg'
import gal4 from '../../assets/images/gallery/perfil1.jpg'
import gal5 from '../../assets/images/gallery/perfil2.jpg'
import About2 from '../../components/AboutUs/AboutUs2'
import HistorySection from '../../components/HistorySection/HistorySection'
import ProjectSection from '../../components/ProjectSection/ProjectSection'

const categories = [
  {
    image: gal1,
    title: 'Produtos de Alumínio de Alta Durabilidade e Resistência à Corrosão',
  },
  {
    image: gal2,
    title: 'Soluções Personalizadas em Alumínio para Projetos Arquitetônicos',
  },
  {
    image: gal3,
    title: 'Alumínio de Alta Eficiência Energética para Janelas e Portas',
  },
  {
    image: gal4,
    title: 'Perfis de Alumínio Leves e Resistentes para Estruturas Modernas',
  },
  {
    image: gal5,
    title: 'Design Inovador em Alumínio para Fachadas e Interiores',
  },
];



const Home = () => {

  return (
    <>
    <Hero/>
   {/* <AboutUs/> */}
    <Segmentos/>
    <div className="divider-50"></div>
    <About2 hclass={'wpo-about-section-s2 section-padding'} />
    <div className="divider-50"></div>
    <HistorySection hclass={'wpo-history-section'}/>
    <div className="divider-50"></div>
    <Category items={categories} />
    <div className="divider-50"></div>
    <ProjectSection hclass={'wpo-project-section'}/>
    {/* <BlogSection/> */}
    {/* <SlantedGridGallery/> */}
    </>
  )
}

export default Home


