import SubCategoryGeneric from "../Subcategories/SubCategoryGeneric";

export const categories = {
  // "Perfis Tabelados": ["Tabelados"],
  "Esquadrias Padrão": [
    "Contramarcos e conexões",
    "Arremates",
    "Linha 25",
    "Linha 30",
    "Linha ME",
    "Venezianas",
  ],
  "Portas Omega": ["Portas Omega RL", "Portas Omega"],
  "Esquadrias Omega": ["Omega 2.5", "Omega 3.2"],
  "Linha Temperado": [
    "Box Temperados",
    "Temperados 8mm",
    "Temperados 10mm",
    "Vitrine",
  ],
  "Fachadas, Portas e Cercas": [
    "Fachada Omega",
    "Fachada PVII",
    "Cercas e Portões",
    "Fachada Cortina",
    "Fachada Unitizada",
  ],
  "Linha Industrial": ["Carrocerias", "Ferragens", "Box Truss", "Ventilação"],

  "Perfis Gerais": [
    "Brises",
    "Guarda-Corpo",
    "Box Acrílico",
    "Lambris",
    "Moveleiro",
    "Escadas",
    "Diversos",
    "Fechamentos de Sacadas",
  ],
};

// Complete configuration for ALL subcategories
const subcategoryConfig = {
  /* ===== Esquadrias Padrão ===== */
  "Contramarcos e conexões": {
    content: [
      {
        paragraph:
          "A Omega oferece contramarcos e conexões de alumínio com precisão milimétrica, fundamentais para a estruturação de esquadrias comerciais. Fabricados sob a norma ABNT NBR 8116, esses componentes garantem estabilidade e durabilidade, recebendo ainda acabamento QUALICOAT para resistência à corrosão. Soluções ideais para projetos que exigem montagem ágil e desempenho técnico comprovado.",
        image: "/images/comercial/contramarcos-instalacao.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/1-esquadrias-padrao/Contramarco/Contramarco.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/1-esquadrias-padrao/1-esquadrias-padrao.pdf",
  },
  Arremates: {
    content: [
      {
        paragraph:
          "Nossos arremates combinam funcionalidade e estética, finalizando esquadrias com perfeição. Produzidos em alumínio extrudado com certificação de composição química controlada, garantem proteção contra infiltrações e desgaste. Acabamentos personalizáveis em pintura eletrostática atendem às exigências de projetos arquitetônicos sofisticados.",
        image: "/images/comercial/arremates-bordas.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/1-esquadrias-padrao/ARREMATES/arremates.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/1-esquadrias-padrao/1-esquadrias-padrao.pdf",
  },
  "Linha 25": {
    content: [
      {
        paragraph:
          "Símbolo de versatilidade, a Linha 25 da Omega é desenvolvida para esquadrias comerciais de alto tráfego. Com perfis extrudados em ligas específicas e tratamentos superficiais QUALICOAT, oferece resistência mecânica superior e opções de design modular. Inclui certificado de fabricação para rastreabilidade total.",
        image: "/images/comercial/linha25-perfil.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/1-esquadrias-padrao/LINHA25/LINHA25.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/1-esquadrias-padrao/1-esquadrias-padrao.pdf",
  },
  "Linha 30": {
    content: [
      {
        paragraph:
          "Perfis robustos para esquadrias comerciais de grande dimensão. Desenvolvidos em ligas de alumínio 6063-T5, suportam vidros espessos e cargas de vento intensas. Incluem sistema de drenagem integrado e certificação de estanqueidade, com opções de pintura eletrostática em cores RAL personalizadas.",
        image: "/images/comercial/linha30-estrutural.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/1-esquadrias-padrao/LINHA30/LINHA30.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/1-esquadrias-padrao/1-esquadrias-padrao.pdf",
  },
  "Linha ME": {
    content: [
      {
        paragraph:
          "Especialmente projetada para montagens econômicas sem sacrificar desempenho. Perfis de múltiplas câmaras extrudados em matrizes de alta precisão, compatíveis com ferragens padrão do mercado. Oferece certificado de composição química para aplicações em ambientes salinos.",
        image: "/images/comercial/linhame-rapida.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/1-esquadrias-padrao/LINHAME/LINHAME.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/1-esquadrias-padrao/1-esquadrias-padrao.pdf",
  },
  Venezianas: {
    content: [
      {
        paragraph:
          "Lâminas ajustáveis de alumínio com tratamento anticorrosivo QUALICOAT CLASS 2. Projetadas para ventilação controlada e proteção solar, incluem mecanismo de inclinação com travamento de segurança. Opções de perfis pintados ou anodizados para harmonização arquitetônica.",
        image: "/images/comercial/venezianas-controle.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/1-esquadrias-padrao/VENEZIANAS/Venezianas.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/1-esquadrias-padrao/1-esquadrias-padrao.pdf",
  },

  /* ===== Portas Omega ===== */
  "Portas Omega RL": {
    content: [
      {
        paragraph:
          "Projetadas para segurança e durabilidade, as Portas Omega RL utilizam alumínio estrutural com núcleo reforçado. Cumprem normas anti-intrusão e recebem tratamento anticorrosivo em todas as superfícies. Soluções ideais para ambientes corporativos, com opções de automatização integrada.",
        image: "/images/portas/omega-rl-fechamento.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/2-portas-omega/PortasOMEGARL/PortasOMEGARL.pdf",
    categoryPdfLink: "assets/pdfbyCategory/2-portas-omega/2-portas-omega.pdf",
  },
  "Portas Omega": {
    content: [
      {
        paragraph:
          "Portas de alumínio maciço com núcleo termoacústico, extrudadas em perfis de 2.0mm de espessura. Sistema de vedação perimetral tripla e dobradiças reforçadas, certificadas para ciclos de abertura intensivos. Personalização com revestimento wood finish ou cores metálicas.",
        image: "/images/portas/omega-standard.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/2-portas-omega/PORTASOMEGA/PORTASOMEGA.pdf",
    categoryPdfLink: "assets/pdfbyCategory/2-portas-omega/2-portas-omega.pdf",
  },

  /* ===== Esquadrias Omega ===== */
  "Omega 2.5": {
    content: [
      {
        paragraph:
          "A linha Omega 2.5 redefine eficiência em esquadrias de pequeno porte. Com perfis de 2.5mm de espessura extrudados em matrizes próprias, oferece relação peso-resistência otimizada. Inclui certificação de estanqueidade para aplicações em fachadas ventiladas.",
        image: "/images/onica/omega2.5-carga.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/3-esquadrias-omega/LINHAOMEGA25/LINHAOMEGA25.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/3-esquadrias-omega/3-esquadrias-omega.pdf",
  },
  "Omega 3.2": {
    content: [
      {
        paragraph:
          "Linha premium para esquadrias estruturais com perfis de 3.2mm de espessura. Compatível com vidros insulados de até 12mm, inclui ruptura térmica certificada e sistema de fixação oculta. Opcional com sensores de integridade estrutural para monitoramento remoto.",
        image: "/images/onica/omega3.2-premium.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/3-esquadrias-omega/Omega32/Omega32.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/3-esquadrias-omega/3-esquadrias-omega.pdf",
  },

  /* ===== 4- Linha Temperado ===== */
  "Box Temperados": {
    content: [
      {
        paragraph:
          "Vidros temperados de 8mm a 12mm montados em estruturas de alumínio Omega com fixação invisível. Soluções para banheiros e áreas molhadas, com tratamento superficial antimancha e garantia contra deformações. Atendem a normas de segurança para vidros laminados.",
        image: "/images/temperada/box-antiestilhaco.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/4-linha-temperada/boxtemperados/boxtemperados.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/4-linha-temperada/4-linha-temperada.pdf",
  },
  "Temperados 8mm": {
    content: [
      {
        paragraph:
          "Vidros temperados de segurança com espessura 8mm, cortados sob medida para encaixe perfeito em perfis Omega. Resistência a impactos equivalente a 5x o vidro comum, com bordas polidas e marcação CE. Soluções para tampos de mesa e divisórias internas.",
        image: "/images/temperada/8mm-divisorias.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/4-linha-temperada/temperados8mm/temperados8mm.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/4-linha-temperada/4-linha-temperada.pdf",
  },
  "Temperados 10mm": {
    content: [
      {
        paragraph:
          "Vidração estrutural para aplicações de alto risco, como corrimãos ou fachadas elevadas. Processo de têmpera controlada garante tensão superficial homogênea, compatível com sistemas de fixação mecânica Omega. Inclui certificado de conformidade ABNT NBR 7199.",
        image: "/images/temperada/10mm-fachada.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/4-linha-temperada/temperados10mm/temperados10mm.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/4-linha-temperada/4-linha-temperada.pdf",
  },
  Vitrine: {
    content: [
      {
        paragraph:
          "Sistemas para vitrines comerciais com vidros temperados de 6mm a 12mm. Perfis de alumínio com ranhuras precisas para montagem rápida, disponíveis em acabamento brilhante ou fosco. Opcional com película anti-UV e proteção antifurto.",
        image: "/images/temperada/vitrine-montagem.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/4-linha-temperada/vitrine/vitrine.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/4-linha-temperada/4-linha-temperada.pdf",
  },

  /* ===== 5- Fachadas, Portas e Cercas ===== */
  "Fachada Omega": {
    content: [
      {
        paragraph:
          "Sistemas de fachadas pré-fabricadas com perfis extrudados sob demanda. Integra vedação termoacústica e estruturas para vidros duplos ou painéis compostos. Projetos certificados para carga de vento regional, com opções de brise soleil integrado.",
        image: "/images/fachadas/omega-montagem.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/5-fachadas-portas-cercas/fachadaomega/fachadaomega.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/5-fachadas-portas-cercas/5-fachadas-portas-cercas.pdf",
  },
  "Fachada PVII": {
    content: [
      {
        paragraph:
          "Sistema modular de fachadas ventiladas com perfis extrudados de 150mm a 300mm de profundidade. Permite revestimento em composite alumínio, cerâmica ou GRC. Projetado para edifícios de múltiplos pavimentos, com certificação para expansão térmica.",
        image: "/images/fachadas/pvii-drenagem.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/5-fachadas-portas-cercas/fachada-civil-pvII/fachada-civil-pvII.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/5-fachadas-portas-cercas/5-fachadas-portas-cercas.pdf",
  },
  "Cercas e Portões": {
    content: [
      {
        paragraph:
          "Estruturas de segurança extrudadas em alumínio naval 5083. Inclui perfis tubulares ovalados com tratamento interno contra condensação e kits de montagem anti-arrombamento. Compatível com automação de até 500kg de peso.",
        image: "/images/fachadas/cercas-portoes.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/5-fachadas-portas-cercas/cercaseportoes/cercaseportoes.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/5-fachadas-portas-cercas/5-fachadas-portas-cercas.pdf",
  },
  "Fachada Cortina": {
    content: [
      {
        paragraph:
          "Solução para envoltória contínua com perfis verticais e horizontais extrudados sob EN 14024. Suporta painéis de até 3m x 4m, com junta estrutural selada a silicone neutro. Opções de perfis termicamente melhorados para eficiência energética.",
        image: "/images/fachadas/cortina-empresarial.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/5-fachadas-portas-cercas/fachadacortina/fachadacortina.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/5-fachadas-portas-cercas/5-fachadas-portas-cercas.pdf",
  },
  "Fachada Unitizada": {
    content: [
      {
        paragraph:
          "Sistema pré-fabricado para projetos high-tech, com módulos de até 15m² fabricados sob controle dimensional rigoroso. Integra perfis Omega com vidros duplos e shading devices, testados em túnel de vento para certificação ABNT.",
        image: "/images/fachadas/unitizada-modulos.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/5-fachadas-portas-cercas/fachada-unitizada/fachada-unitizada.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/5-fachadas-portas-cercas/5-fachadas-portas-cercas.pdf",
  },

  /* ===== 6- Linha Industrial ===== */
  Carrocerias: {
    content: [
      {
        paragraph:
          "Perfilados especiais para veículos utilitários, desenvolvidos em ligas de alumínio naval. Combinação de leveza (até 40% mais leves que o aço) e resistência à vibração, com tratamento anodizado para ambientes corrosivos. Inclui certificado de resistência à tração.",
        image: "/images/industrial/carroceria-caminhao.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/6-linha-industrial/carrocerias/carrocerias.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/6-linha-industrial/6-linha-industrial.pdf",
  },
  Ferragens: {
    content: [
      {
        paragraph:
          "Componentes de alta precisão para fixação e movimentação de esquadrias, fabricados em aço inoxidável ou alumínio tratado termicamente. Inclui dobradiças com sistema de rolamento selado, trilhos para corrediças telescópicas e fechaduras com certificação anti-arrombamento. Compatíveis com todos os perfis Omega, garantindo ciclos de operação suaves mesmo em uso intensivo.",
        image: "/images/industrial/ferragens-construcao.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/6-linha-industrial/ferragens/ferragens.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/6-linha-industrial/6-linha-industrial.pdf",
  },
  "Box Truss": {
    content: [
      {
        paragraph:
          "Estruturas modulares para suporte de cargas em eventos e construções temporárias. Perfis tubulares extrudados em liga 6061-T6, com conexões rótula patenteadas que suportam até 1,2 toneladas por nó. Tratamento superficial anodizado classe III para resistência a intempéries. Certificado para montagem em altura conforme NR-18.",
        image: "/images/industrial/truss-eventos.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/6-linha-industrial/box-truss/box-truss.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/6-linha-industrial/6-linha-industrial.pdf",
  },
  Ventilação: {
    content: [
      {
        paragraph:
          "Sistemas de exaustão e renovação de ar com grelhas extrudidas em perfis aerodinâmicos. Desenhos computacionalmente otimizados (CFD) para vazões de até 3.000 m³/h, com opções de filtros integrados e dampers motorizados. Atendem normas de qualidade do ar ABNT NBR 16401 para ambientes industriais.",
        image: "/images/industrial/ventilacao-exaustao.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/6-linha-industrial/ventilacao/ventilacao.pdf",
    categoryPdfLink:
      "assets/pdfbyCategory/6-linha-industrial/6-linha-industrial.pdf",
  },

  /* ===== Perfis Gerais ===== */
  Brises: {
    content: [
      {
        paragraph:
          "Elementos de proteção solar extrudados com geometrias calculadas para eficiência energética. Compatíveis com sistemas de automação, fabricados em alumínio com certificação de refletividade superficial. Soluções personalizáveis para projetos LEED.",
        image: "/images/gerais/brises-controle.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/7-perfis-gerais/brises/brises.pdf",
    categoryPdfLink: "assets/pdfbyCategory/7-perfis-gerais/7-perfis-gerais.pdf",
  },
  "Guarda-Corpo": {
    content: [
      {
        paragraph:
          "Sistemas de proteção em alumínio estrutural com design minimalista. Testados para cargas de impacto seguindo normas técnicas, incluem opções com vidro blindado ou painéis perfurados. Acabamentos disponíveis em anodizado classe II ou pintura PVDF.",
        image: "/images/gerais/guarda-corpo-alto.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/7-perfis-gerais/guarda-corpo/guarda-corpo.pdf",
    categoryPdfLink: "assets/pdfbyCategory/7-perfis-gerais/7-perfis-gerais.pdf",
  },
  "Box Acrílico": {
    content: [
      {
        paragraph:
          "Soluções modernas para divisórias e enclosures, combinando perfis de alumínio Omega com chapas de acrílico de alta transparência. Estruturas extrudadas com reforço interno garantem estabilidade, enquanto o acabamento QUALICOAT protege contra riscos e oxidação. Ideal para ambientes hospitalares e comerciais que exigem higiene e leveza visual.",
        image: "/images/gerais/box-acrilico.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/7-perfis-gerais/box-acrilico/box-acrilico.pdf",
    categoryPdfLink: "assets/pdfbyCategory/7-perfis-gerais/7-perfis-gerais.pdf",
  },
  Lambris: {
    content: [
      {
        paragraph:
          "Revestimentos modulares para paredes e tetos, com sistema de encaixe invisível. Perfis de 0,8mm a 1,2mm de espessura, disponíveis em acabamentos woodgrain transferido a vácuo ou pintura texturizada. Incluem certificado de resistência ao fogo classe A2-s1,d0 para aplicações corporativas.",
        image: "/images/gerais/lambris-interno.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/7-perfis-gerais/lambris/lambris.pdf",
    categoryPdfLink: "assets/pdfbyCategory/7-perfis-gerais/7-perfis-gerais.pdf",
  },
  Moveleiro: {
    content: [
      {
        paragraph:
          "Soluções para mobiliário comercial e industrial, com perfis de múltiplas cânhamas para fixação oculta. Compatíveis com sistemas de montagem rápida, incluem guias para gavetas com capacidade de 50kg e perfis estruturais para prateleiras de até 3m de vão livre. Certificação ANSI/BIFMA para durabilidade.",
        image: "/images/gerais/moveleiro-comercial.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/7-perfis-gerais/moveleiro/moveleiro.pdf",
    categoryPdfLink: "assets/pdfbyCategory/7-perfis-gerais/7-perfis-gerais.pdf",
  },
  Escadas: {
    content: [
      {
        paragraph:
          "Componentes para escadas metálicas com degraus antiderrapantes integrados. Perfis principais extrudidos em formato T-slotted para fixação modular de guarda-corpos. Testados para carga distribuída de 500kg/m² conforme ABNT NBR 14718, com opções de revestimento em fibra de vidro.",
        image: "/images/gerais/escadas-metalicas.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/7-perfis-gerais/escadas/escadas.pdf",
    categoryPdfLink: "assets/pdfbyCategory/7-perfis-gerais/7-perfis-gerais.pdf",
  },
  Diversos: {
    content: [
      {
        paragraph:
          "Perfis especiais para aplicações não convencionais, desde suportes para automação até estruturas para agricultura de precisão. Fabricados sob demanda com tolerâncias dimensionais de ±0,1mm, utilizando matrizes de extrusão de última geração. Garantia de composição química controlada para aplicações críticas.",
        image: "/images/gerais/acessorios-especiais.jpg",
      },
    ],
    pdfLink: "assets/pdfbyCategory/7-perfis-gerais/diversos/diversos.pdf",
    categoryPdfLink: "assets/pdfbyCategory/7-perfis-gerais/7-perfis-gerais.pdf",
  },
  "Fechamentos de Sacadas": {
    content: [
      {
        paragraph:
          "Sistemas completos para proteção de varandas, combinando perfis estruturais e painéis de vidro ou policarbonato alveolar. Incluem trilhos superiores extrudidos com canal para iluminação LED integrada e perfis inferiores com dreno oculto. Certificado para carga de vento de até 150km/h em edificações altas.",
        image: "/images/gerais/sacadas-protecao.jpg",
      },
    ],
    pdfLink:
      "assets/pdfbyCategory/7-perfis-gerais/fechamentosdesacadas/fechamentosdesacadas.pdf",
    categoryPdfLink: "assets/pdfbyCategory/7-perfis-gerais/7-perfis-gerais.pdf",
  },
};

// Component factory
const createSubcategory = (category, subcategory) => (
  <SubCategoryGeneric
    {...subcategoryConfig[subcategory]}
    categoryName={category}
    subcategoryName={subcategory}
    pdfLink={
      subcategoryConfig[subcategory]?.pdfLink ||
      `/pdfs/${category.replace(/ /g, "_")}/${subcategory.replace(
        / /g,
        "_"
      )}.pdf`
    }
  />
);

// Complete category-subcategory mapping
export const subcategoryContent = {
  "Esquadrias Padrão": {
    "Contramarcos e conexões": createSubcategory(
      "Esquadrias Padrão",
      "Contramarcos e conexões"
    ),
    Arremates: createSubcategory("Esquadrias Padrão", "Arremates"),
    "Linha 25": createSubcategory("Esquadrias Padrão", "Linha 25"),
    "Linha 30": createSubcategory("Esquadrias Padrão", "Linha 30"),
    "Linha ME": createSubcategory("Esquadrias Padrão", "Linha ME"),
    Venezianas: createSubcategory("Esquadrias Padrão", "Venezianas"),
  },
  "Portas Omega": {
    "Portas Omega RL": createSubcategory("Portas Omega", "Portas Omega RL"),
    "Portas Omega": createSubcategory("Portas Omega", "Portas Omega"),
  },
  "Esquadrias Omega": {
    "Omega 2.5": createSubcategory("Esquadrias Omega", "Omega 2.5"),
    "Omega 3.2": createSubcategory("Esquadrias Omega", "Omega 3.2"),
  },
  "Linha Temperado": {
    "Box Temperados": createSubcategory("Linha Temperado", "Box Temperados"),
    "Temperados 8mm": createSubcategory("Linha Temperado", "Temperados 8mm"),
    "Temperados 10mm": createSubcategory("Linha Temperado", "Temperados 10mm"),
    Vitrine: createSubcategory("Linha Temperado", "Vitrine"),
  },
  "Fachadas, Portas e Cercas": {
    "Fachada Omega": createSubcategory(
      "Fachadas, Portas e Cercas",
      "Fachada Omega"
    ),
    "Fachada PVII": createSubcategory(
      "Fachadas, Portas e Cercas",
      "Fachada PVII"
    ),
    "Cercas e Portões": createSubcategory(
      "Fachadas, Portas e Cercas",
      "Cercas e Portões"
    ),
    "Fachada Cortina": createSubcategory(
      "Fachadas, Portas e Cercas",
      "Fachada Cortina"
    ),
    "Fachada Unitizada": createSubcategory(
      "Fachadas, Portas e Cercas",
      "Fachada Unitizada"
    ),
  },
  "Linha Industrial": {
    Carrocerias: createSubcategory("Linha Industrial", "Carrocerias"),
    Ferragens: createSubcategory("Linha Industrial", "Ferragens"),
    "Box Truss": createSubcategory("Linha Industrial", "Box Truss"),
    Ventilação: createSubcategory("Linha Industrial", "Ventilação"),
  },
  "Perfis Gerais": {
    Brises: createSubcategory("Perfis Gerais", "Brises"),
    "Guarda-Corpo": createSubcategory("Perfis Gerais", "Guarda-Corpo"),
    "Box Acrílico": createSubcategory("Perfis Gerais", "Box Acrílico"),
    Lambris: createSubcategory("Perfis Gerais", "Lambris"),
    Moveleiro: createSubcategory("Perfis Gerais", "Moveleiro"),
    Escadas: createSubcategory("Perfis Gerais", "Escadas"),
    Diversos: createSubcategory("Perfis Gerais", "Diversos"),
    "Fechamentos de Sacadas": createSubcategory(
      "Perfis Gerais",
      "Fechamentos de Sacadas"
    ),
  },
};
