import React, { useRef } from 'react'
import Menu from '../../components/Menu/Menu'
import ProductList from '../../components/ProductList/ProductList'
import ContactSection from '../../components/GetinTouch/GetinTouch'
import Hero4 from '../../components/Hero4/Hero4'

const Product = () => {
  const productListRef = useRef(null);
  return (
    <>
    <Menu dark/>
    <Hero4 hclass={'wpo-hero-slider'} scrollToProduct={() => productListRef.current?.scrollIntoView({ behavior: 'smooth' })} />
    <div ref={productListRef}>
        <ProductList />
      </div>
      <ContactSection/>
    </>
  )
}
export default Product