import React from 'react';
import { Link } from 'react-router-dom';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import gif from '../../assets/video/onica-banner.gif'
// import VideoModal from '../ModalVideo/VideoModal';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const HistorySection = (props) => {
    return (
        <section className={"" + props.hclass}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="content">
                            <span>Nossa História</span>
                            <h2>História, presente e futuro da empresa</h2>
                            <p>Desde a nossa fundação, nos dedicamos à transformação de matéria-prima em produtos de alumínio de alta qualidade. 
                            Nossa trajetória é marcada por inovação, sustentabilidade e compromisso com a excelência.</p>
                            <div className="btn_history">
                                <Link onClick={ClickHandler} to="/sobre-nos">Saiba Mais</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="video-content">
                <LazyLoadImage src={gif} alt="" />
                {/* <VideoModal/> */}
            </div>
        </section>
    );
};

export default HistorySection;