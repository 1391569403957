import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import styled from "styled-components";

const FloatingButton = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 32px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const WhatsAppButton = () => {
  return (
    <FloatingButton
      href="https://wa.me/554532643610" // Replace with your WhatsApp number
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp />
    </FloatingButton>
  );
};

export default WhatsAppButton;
