import styled from "styled-components";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { Link } from "react-router-dom";
import QualityLogo from "../../assets/images/qualicoat.png";
const FooterContainer = styled.footer`
  background-color: var(--color-secondary);
  padding: var(--spacing-large) var(--spacing-medium);
  border-top: 1px solid var(--color-accent);
  font-size: var(--font-size-sm);
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-large);
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  min-width: 200px;
  padding: var(--spacing-medium);

  h3 {
    font-size: var(--font-size-md);
    margin-bottom: var(--spacing-small);
    color: var(--color-primary);
  }
`;

const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  a {
    color: var(--color-text);
    transition: color 0.3s ease;

    &:hover {
      color: var(--color-primary);
    }
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    margin: 4px 0;
    line-height: 1.4;
    color: white;
    font-size: var(--spacing-small);
  }
`;

const SocialMedia = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 12px;

  a {
    color: var(--color-text);
    transition: color 0.3s ease;
    font-size: 24px;
    display: flex;
    align-items: center;

    &:hover {
      color: var(--color-primary);
    }
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: var(--spacing-large);
  padding-top: var(--spacing-medium);
  border-top: 1px solid var(--color-accent);
  font-size: var(--font-size-xs);
  line-height: 1.5;

  a {
    color: var(--color-text);
    margin: 0 8px;

    &:hover {
      color: var(--color-primary);
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>A Empresa</h3>
          <LinkList>
            <li>
              <Link to="/sobre-nos">Sobre Nós</Link>
            </li>
            <li>
              <Link to="/produtos">Produtos</Link>
            </li>
            <li>
              <Link to="/contatos">Contatos</Link>
            </li>
          </LinkList>
        </FooterSection>

        <FooterSection>
          <h3>Contato</h3>
          <ContactInfo>
            <p>Rua Arcângelo João Grapiglia, 61</p>
            <p>Área Industrial, Medianeira, PR, Brasil</p>
            <p>(45) 3264-3610</p>
          </ContactInfo>
        </FooterSection>

        <FooterSection>
          <h3>Redes Sociais</h3>
          <SocialMedia>
            <a
              href="https://www.facebook.com/profile.php?id=61568965868313"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://www.linkedin.com/company/105695510/admin/dashboard/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn />
            </a>
            <a
              href="https://www.instagram.com/omegaluminios?utm_source=qr&igsh=c3J5OXlmMm02cHUz"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
            {/* <a href="#"><FaYoutube /></a> */}
          </SocialMedia>
        </FooterSection>
        <FooterSection>
          <h3>Certificaçãos</h3>
          <ImgContainer>
            <img src={QualityLogo} alt="" />
          </ImgContainer>
        </FooterSection>
      </FooterContent>

      <Copyright>
        <div>Copyright © 2025 Omega – todos os direitos reservados</div>
        <div>
          <a href="/termos-privacidade">Privacidade e temos de uso</a>
        </div>
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;

const ImgContainer = styled.div`
  width:150px;
  hright:70px
  & img{
    width:100%;
  hright:100%:
  }
`;
