import styled from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
const GridContainer = styled.div`
  display: grid;
  gap: var(--spacing-medium);
  padding: var(--spacing-medium);
  max-width: 1440px;
  margin: 0 auto;

  @media (min-width: 768px) {
    gap: var(--spacing-large);
    padding: var(--spacing-large);
  }
`;

const GridRow = styled.div`
  display: grid;
  gap: var(--spacing-medium);
  width: 100%;

  &:first-child {
    grid-template-columns: 1fr;
    
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &:last-child {
    grid-template-columns: 1fr;
    
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    
    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const GridItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  aspect-ratio: ${props => props.rowType === 'first' ? '4/3' : '3/2'};
  background: var(--color-accent);
  transition: transform 0.3s ease;
  &:hover {
    
    img {
      transform: scale(1.05);
    }
  }
`;

const Image = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
`;

const TextOverlay = styled.div`
  position: absolute;
  bottom: var(--spacing-medium);
  left: var(--spacing-medium);
  color: var(--color-primary);
  z-index: 2;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-medium);
  font-size: var(--font-size-md);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  
  @media (min-width: 768px) {
    font-size: var(--font-size-lg);
    bottom: var(--spacing-large);
    left: var(--spacing-large);
  }
  
  @media (min-width: 1024px) {
    font-size: var(--font-size-xl);
  }
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  z-index: 1;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 2.8rem;
  color: #000B47;
  font-weight: 700;
  line-height: 35px;
  
`;

const Description = styled.p`
  font-size: var(--font-size-md);
  text-align: center;
  color: var(--color-secondary);
  max-width: 800px;
  margin: 0 auto var(--spacing-large);
  line-height: 1.6;
`;




const Category = ({ items }) => {
  return (
    <>
    <Title>Descubra a Versatilidade do Alumínio</Title>
    <Description>
      O alumínio é um material leve, resistente à corrosão e altamente reciclável. 
      Suas aplicações vão desde a construção civil até a indústria aeroespacial, 
      sendo uma escolha sustentável e eficiente.
    </Description>
    <GridContainer>

      <GridRow>
        {items.slice(0, 3).map((item, index) => (
          <GridItem key={index} rowType="first">
            <Image src={item.image} alt={item.title} />
            <GradientOverlay />
            <TextOverlay>{item.title}</TextOverlay>
          </GridItem>
        ))}
      </GridRow>
      <GridRow>
        {items.slice(3, 5).map((item, index) => (
          <GridItem key={index} rowType="last">
            <Image src={item.image} alt={item.title} />
            <GradientOverlay />
            <TextOverlay>{item.title}</TextOverlay>
          </GridItem>
        ))}
      </GridRow>
    </GridContainer>
    </>
  );
};

export default Category;