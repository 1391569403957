import React, { useState } from "react";
import styled from "styled-components";
import { categories, subcategoryContent } from "./Content";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  position: relative;
  color: var(--color-text-alt);
  font-size: 1.5rem;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background: var(--color-secondary-2);
    border-radius: 2px;
  }
`;

const SelectsContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Select = styled.select`
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border: 2px solid var(--color-secondary-2);
  border-radius: 6px;
  background-color: var(--color-primary);
  color: var(--color-text-alt);
  transition: all 0.2s ease;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000B47%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 10px auto;
  flex: 1;
  min-width: 200px;

  &:focus {
    outline: none;
    border-color: var(--color-secondary-2-dark);
    box-shadow: 0 0 0 2px rgba(255, 94, 20, 0.2);
  }

  &:hover {
    cursor: pointer;
    border-color: var(--color-secondary-2-dark);
  }
`;

const Content = styled.div`
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: var(--color-primary);
  border-radius: 8px;
  width: 100%;
  animation: fadeIn 0.3s ease-in;

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(5px); }
    to { opacity: 1; transform: translateY(0); }
  }

  h2 {
    color: var(--color-text-alt);
    margin-bottom: 1rem;
    font-size: 1.25rem;
    position: relative;
    
    &:after {
      content: "";
      position: absolute;
      bottom: -0.25rem;
      left: 0;
      width: 40px;
      height: 2px;
      background: var(--color-secondary-2);
    }
  }

  p {
    line-height: 1.6;
    color: var(--color-tertiary);
    font-size: 0.875rem;
  }
`;

const CategorySubcategoryViewer = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubcategory("");
  };

  const handleSubcategoryChange = (e) => {
    setSelectedSubcategory(e.target.value);
  };

  return (
    <Container>
      <Title>Explore nossas categorias e nossa linha de produção industrial</Title>

      <SelectsContainer>
        <Select value={selectedCategory} onChange={handleCategoryChange}>
          <option value="">Selecione uma categoria</option>
          {Object.keys(categories).map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </Select>

        {selectedCategory && (
          <Select value={selectedSubcategory} onChange={handleSubcategoryChange}>
            <option value="">Selecione uma subcategoria</option>
            {categories[selectedCategory].map((subcategory) => (
              <option key={subcategory} value={subcategory}>
                {subcategory}
              </option>
            ))}
          </Select>
        )}
      </SelectsContainer>

      {selectedSubcategory && (
        (() => {
          const SubcategoryComponent = subcategoryContent[selectedCategory]?.[selectedSubcategory];
          
          return typeof SubcategoryComponent === "function" ? (
            <Content>
              <SubcategoryComponent />
            </Content>
          ) : (
            <Content>
              <h2>{selectedSubcategory}</h2>
              {SubcategoryComponent}
            </Content>
          );
        })()
      )}
    </Container>
  );
};

export default CategorySubcategoryViewer;