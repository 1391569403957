import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import GlobalStyle from "./GlobalStyle";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import CatalogoPage from "./pages/Catalogo/Catalogo";
import WhatsAppButton from "./components/WhatsAppButton/WhatsAppButton";
import SiteEmConstrucao from "./components/SiteEmConstrucao/SiteEmConstrucao";
import Navbar from "./components/Navbar/Navbar";
import WorkWithUs from "./pages/WorkWithUs/WorkWithUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import Product from "./pages/Product/Product";
import AboutUs from "./pages/AboutUs/AboutUs";
import "react-lazy-load-image-component/src/effects/blur.css";
import NotFound from "./components/NotFound/NotFound";
import { useEffect } from "react";
import OmegaPrivacyTerms from "./components/Privacidade/Privacidade";

function App() {
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contatos" element={<ContactUs />} />
        <Route path="/catalogo" element={<CatalogoPage />} />
        <Route path="/produtos" element={<Product />} />
        <Route path="/trabalhe-conosco" element={<WorkWithUs />} />
        <Route path="/blog" element={<SiteEmConstrucao />} />
        <Route path="/sobre-nos" element={<AboutUs />} />
        <Route path="/termos-privacidade" element={<OmegaPrivacyTerms />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <WhatsAppButton />
    </Router>
  );
}

export default App;

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [pathname]);

  return null;
};
