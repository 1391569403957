import React from 'react';
import styled from 'styled-components';
import { FaDownload, FaEye } from 'react-icons/fa';
import img1 from "../../assets/images/catalogo1.jpg";
import img2 from "../../assets/images/catalogo2.jpg";
import Menu from '../../components/Menu/Menu';

// Styled components
const Container = styled.section`
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  min-height: 100vh;
`;

const HeaderSection = styled.div`
  text-align: center;
  max-width: 1200px;
  margin: 0 auto 4rem;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #2d3436;
  letter-spacing: -0.5px;
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background: var(--color-secondary-2);
    border-radius: 2px;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #636e72;
  max-width: 800px;
  margin: 0 auto;
`;

const CatalogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2.5rem;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const CatalogItem = styled.div`
  background: white;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 10px 30px rgba(0,0,0,0.08);
  position: relative;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 400px;
  overflow: hidden;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 60%, rgba(0,0,0,0.7) 100%);
    z-index: 1;
  }
`;

const CatalogImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${CatalogItem}:hover & {
    transform: scale(1.05);
  }
`;

const CatalogContent = styled.div`
  padding: 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  color: white;
`;

const CatalogName = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color:var(--color-primary);
`;

const CatalogMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  opacity: 0.9;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 0.75rem;
  padding: 1.5rem;
`;

const CatalogButton = styled.a`
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  text-decoration: none;
  background: ${props => props.primary ? 'var(--color-secondary-2)' : '#2d3436'};
  color: white;
  &:hover {
    background: ${props => props.primary ? 'var(--color-secondary-2-dark)' : '#1a1e1f'};
    color: white;
    transform: translateY(-2px);
  }
  svg {
    font-size: 1.1rem;
  }
`;

const CatalogoPage = () => {
  const catalogs = [
    {
      id: 1,
      name: 'Catálogo Geral',
      image: img1,
      pdf: '/assets/pdf/catalogo-junio.pdf',
      pages: 170,
      updated: 'Publicado em 2025'
    },
    {
      id: 2,
      name: 'Novos Perfis Industriais',
      image: img2,
      pdf: '/assets/pdf/catalogo-marzo.pdf',
      pages: 11,
      updated: 'Publicado em: Março 2025'
    }
  ];

  return (
    <>
      <Menu dark/>
      <Container>
        <HeaderSection>
          <Title>Catálogos Técnicos</Title>
          <Description>
            Explore nossa linha completa de perfis de alumínio de alta performance. 
            Nossos catálogos técnicos oferecem especificações detalhadas, aplicações 
            recomendadas e dados técnicos para auxiliar engenheiros e arquitetos em 
            projetos de excelência.
          </Description>
        </HeaderSection>
        
        <CatalogGrid>
          {catalogs.map((catalog) => (
            <CatalogItem key={catalog.id}>
              <ImageContainer>
                <CatalogImage
                  src={catalog.image}
                  alt={`Capa do ${catalog.name}`}
                />
                <CatalogContent>
                  <CatalogName>{catalog.name}</CatalogName>
                  <CatalogMeta>
                    <span>{catalog.pages} páginas</span>
                    <span>•</span>
                    <span>{catalog.updated}</span>
                  </CatalogMeta>
                </CatalogContent>
              </ImageContainer>
              
              <ButtonGroup>
                <CatalogButton 
                  href={catalog.pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  primary
                >
                  <FaEye />
                  Visualizar
                </CatalogButton>
                <CatalogButton 
                  href={catalog.pdf}
                  download
                >
                  <FaDownload />
                  Download
                </CatalogButton>
              </ButtonGroup>
            </CatalogItem>
          ))}
        </CatalogGrid>
      </Container>
    </>
  );
};

export default CatalogoPage;