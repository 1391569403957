import React from 'react';
import { Link } from 'react-router-dom';
import About1 from '../../assets/images/ab-1.jpeg';
import About3 from '../../assets/images/ab-2.jpeg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const About2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className={"" + props.hclass}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="about-left-image">
                            <div className="ab-3">
                                <LazyLoadImage  src={About1} alt="" effect="blur" />
                            </div>
                            <div className="ab-4">
                                <LazyLoadImage  src={About3} alt="" effect="blur" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="content">
                            <div className="text">
                                <h2>Sobre a Empresa</h2>
                                <h3>Resolvemos Todos os Desafios do Seu Negócio</h3>
                                <p>
                                    Nossa empresa é especializada na transformação de matéria-prima em produtos de alumínio de alta qualidade. 
                                    Oferecemos soluções personalizadas para atender às necessidades de diversos setores industriais.
                                </p>
                            </div>
                            <div className="list">
                                <ul>
                                    <li><i className="ti-check"></i> <span>Matéria-Prima de Qualidade</span></li>
                                    <li><i className="ti-check"></i> <span>Controle de Qualidade Rigoroso</span></li>
                                    <li><i className="ti-check"></i> <span>Produtos Acabados Sob Medida</span></li>
                                </ul>
                                <ul>
                                    <li><i className="ti-check"></i> <span>Tecnologia de Ponta</span></li>
                                    <li><i className="ti-check"></i> <span>Sustentabilidade Ambiental</span></li>
                                    <li><i className="ti-check"></i> <span>Atendimento Personalizado</span></li>
                                </ul>
                            </div>
                            <div className="about-btn">
                                <Link onClick={ClickHandler} to="/sobre-nos" className="theme-btn">Sobre Nós</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About2;