import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";

const ContactForm = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      className: "errorMessage",
      messages: {
        required: "Este campo é obrigatório.",
        email: "Por favor, insira um e-mail válido.",
        alpha_space: "Deve conter apenas letras e espaços.",
        phone: "Formato inválido (ex: 45123456789)",
      },
      validators: {
        phone: {
          message: "Telefone inválido",
          rule: (val) =>
            /(^\d{10,11}$)|(^\(\d{2}\)\s?\d{4,5}-\d{4}$)/.test(val),
        },
      },
    })
  );

  const [status, setStatus] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForms((prev) => ({ ...prev, [name]: value }));
    if (submitted) validator.current.showMessageFor(name);
    forceUpdate({});
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (!validator.current.allValid()) {
      validator.current.showMessages();
      forceUpdate({});
      setStatus("❌ Por favor, preencha todos os campos corretamente");
      return;
    }

    setIsSending(true);
    setStatus("📨 Enviando...");

    try {
      const response = await fetch(
        "https://rz33zjqjw7.execute-api.sa-east-1.amazonaws.com/dev/contact-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(forms),
        }
      );

      const data = await response.json();

      if (response.ok && data.success) {
        setStatus(`✅ ${data.message || "Mensagem enviada com sucesso!"}`);
        setForms({
          name: "",
          email: "",
          subject: "",
          phone: "",
          message: "",
        });
        validator.current.hideMessages();
        setSubmitted(false); // reset validation
        forceUpdate({});
      } else {
        setStatus(`❌ Erro: ${data.error || "Erro desconhecido."}`);
      }
    } catch (err) {
      setStatus(`❌ Falha no envio: ${err.message}`);
    } finally {
      setIsSending(false);
    }
  };

  const showError = (field, rules) => {
    return submitted
      ? validator.current.message(field, forms[field], rules)
      : "";
  };

  return (
    <form onSubmit={submitHandler} className="contact-validation-active">
      <div className="row">
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              type="text"
              name="name"
              placeholder="Seu Nome"
              value={forms.name}
              onChange={changeHandler}
              disabled={isSending}
            />
            <div style={{ color: "#ff0000", fontSize: "0.875rem" }}>
              {showError("name", "required|alpha_space")}
            </div>
          </div>
        </div>

        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              type="email"
              name="email"
              placeholder="Seu E-mail"
              value={forms.email}
              onChange={changeHandler}
              disabled={isSending}
            />
            <div style={{ color: "#ff0000", fontSize: "0.875rem" }}>
              {showError("email", "required|email")}
            </div>
          </div>
        </div>

        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              type="text"
              name="phone"
              placeholder="Seu Telefone"
              value={forms.phone}
              onChange={changeHandler}
              disabled={isSending}
            />
            <div style={{ color: "#ff0000", fontSize: "0.875rem" }}>
              {showError("phone", "required|phone")}
            </div>
          </div>
        </div>

        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <select
              name="subject"
              value={forms.subject}
              onChange={changeHandler}
              disabled={isSending}
            >
              <option value="">Selecione um serviço</option>
              <option>Venda e orçamento de perfis de Alumínio</option>
              <option>Serviço de extrusão e pintura</option>
              <option>Outro motivo</option>
            </select>
            <div style={{ color: "#ff0000", fontSize: "0.875rem" }}>
              {showError("subject", "required")}
            </div>
          </div>
        </div>

        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <textarea
              name="message"
              placeholder="Mensagem"
              value={forms.message}
              onChange={changeHandler}
              disabled={isSending}
            ></textarea>
            <div style={{ color: "#ff0000", fontSize: "0.875rem" }}>
              {showError("message", "required")}
            </div>
          </div>
        </div>
      </div>

      <div className="submit-area">
        <button
          type="submit"
          className="theme-btn"
          disabled={isSending}
          style={{ opacity: isSending ? 0.7 : 1 }}
        >
          {isSending ? "Enviando..." : "Enviar Mensagem"}
        </button>
        {status && (
          <p
            style={{
              color: status.includes("✅") ? "#28a745" : "#dc3545",
              marginTop: "1rem",
              fontWeight: "bold",
            }}
          >
            {status}
          </p>
        )}
      </div>
    </form>
  );
};

export default ContactForm;
