import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  /* Import Google Fonts */
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&family=Roboto:wght@300;400;500&display=swap');


  :root {
    /* Global Colors */
    --color-primary: #FFFFFF;
    --color-secondary: #131212;
    --color-secondary-2: #FF5E14;
    --color-secondary-2-dark:rgb(218, 82, 20);
    --color-text: #FFFFFF;
    --color-text-alt:#000B47;
    --color-accent: #2a2a2a;
    --color-tertiary: #4a4a4a;
    /* Global Typography */
    --font-family-primary: "Montserrat", sans-serif;
    --font-weight-primary-light: 300;
    --font-weight-primary-regular: 400;
    --font-weight-primary-medium: 500;

    --font-family-secondary: "Roboto", sans-serif;
    --font-weight-secondary-light: 300;
    --font-weight-secondary-regular: 400;
    --font-weight-secondary-medium: 500;

    --font-family-text: var(--font-family-secondary);
    --font-weight-text-regular: var(--font-weight-secondary-regular);

    --font-family-accent: var(--font-family-secondary);
    --font-weight-accent-medium: var(--font-weight-secondary-medium);

    /* Global Spacing */
    --spacing-extrasmall: 8px;
    --spacing-small: 12px;
    --spacing-medium: 16px;
    --spacing-large: 24px;

    /* Responsive Font Sizes */
    --font-size-xs: 12px;
    --font-size-sm: 14px;
    --font-size-md: 16px;
    --font-size-lg: 18px;
    --font-size-xl: 20px;
    --font-size-xxl: 24px;
  }

  /* Responsive Typography */
  html {
    font-size: var(--font-size-md);

    @media (min-width: 768px) {
      font-size: var(--font-size-lg);
    }

    @media (min-width: 1024px) {
      font-size: var(--font-size-xl);
    }

    @media (min-width: 1440px) {
      font-size: var(--font-size-xxl);
    }
  }
  html, body {
  width: 100%;
  overflow-x: hidden;
}
  body {
    font-family: var(--font-family-text);
    font-weight: var(--font-weight-text-regular);
    color: var(--color-text);
    background-color: var(--color-background-modulo);
    line-height: 1.6;
    margin: 0;
    padding: 0;
    
  }
ul{
  color: #687693;
}
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-primary);
    font-weight: var(--font-weight-primary-medium);
    color: black;
    margin: 0;
    line-height: 1.2;
  }

  h1 {
    font-size: 24px;

    @media (min-width: 768px) {
      font-size: 28px;
    }

    @media (min-width: 1024px) {
      font-size: 32px;
    }

    @media (min-width: 1440px) {
      font-size: 36px;
    }
  }

  h2 {
    font-size: 1.25rem;
  span {
    position: relative;
    display: inline-block;
    margin-bottom:.8rem;
    &::after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 120%;
      height: 3px;
      background: linear-gradient(90deg, transparent 0%, #ddd 50%, transparent 100%);
    }
  }
    @media (min-width: 768px) {
      font-size: 1.5rem;
    }

    @media (min-width: 1024px) {
      font-size: 1,75rem;
    }

    @media (min-width: 1440px) {
      font-size: 2.5rem;
    }
      
  }

  h3 {
    font-size: 18px;

    @media (min-width: 768px) {
      font-size: 20px;
    }

    @media (min-width: 1024px) {
      font-size: 24px;
    }

    @media (min-width: 1440px) {
      font-size: 28px;
    }
  }

  h4 {
    font-size: 16px;

    @media (min-width: 768px) {
      font-size: 18px;
    }

    @media (min-width: 1024px) {
      font-size: 20px;
    }

    @media (min-width: 1440px) {
      font-size: 24px;
    }
  }

  h5 {
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 16px;
    }

    @media (min-width: 1024px) {
      font-size: 18px;
    }

    @media (min-width: 1440px) {
      font-size: 20px;
    }
  }

  h6 {
    font-size: 12px;

    @media (min-width: 768px) {
      font-size: 14px;
    }

    @media (min-width: 1024px) {
      font-size: 16px;
    }

    @media (min-width: 1440px) {
      font-size: 18px;
    }
  }

  p {
    font-family: var(--font-family-text);
    font-weight: var(--font-weight-text-regular);
    margin: 0;
  }

  a {
    font-family: var(--font-family-accent);
    font-weight: var(--font-weight-accent-medium);
    color: var(--color-accent);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  a:link, a:visited, a:hover, a:active {
    text-decoration: none;
  }

/* Divider */
.divider-50 {
  padding: 3rem 0; /* Correção: removi o ponto extra após o 3 */
}

@media (max-width: 1280px) {
  .divider-50 {
    padding: 1.5rem 0 !important; /* Correção: removi o ponto extra após o 1 */
    height: auto;
  }
}

.divider-100 {
  padding: 6.25rem 0; /* Correção: removi o ponto extra após o 6 */
}

@media (max-width: 1280px) {
  .divider-100 {
    padding: 3rem 0 !important; /* Correção: removi o ponto extra após o 3 */
    height: auto;
  }
}

`;

export default GlobalStyle;
