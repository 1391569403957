import React from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Import images directly in the component

import pimg1 from "../../assets/images/category/1-esquadrias-padrao.jpeg";
import pimg2 from "../../assets/images/category/2-portas.jpeg";
import pimg3 from "../../assets/images/category/3-esquadrias.jpeg";
import pimg4 from "../../assets/images/category/4-temperada.jpeg";
import pimg5 from "../../assets/images/category/5-cercas.jpeg";
import pimg6 from "../../assets/images/category/5-cercas.jpeg";

const Projects = [
  {
    id: "1",
    title: "Esquadrias Padrão",
    docomunt:
      "Perfis de alumínio para esquadrias comerciais, incluindo janelas e portas com acabamentos precisos",
    slug: "Solar",
    pimg1: pimg1,
    location: "7 Lake Street, London",
    date: "15 Dec 2024",
  },
  {
    id: "2",
    title: "Portas Omega",
    docomunt:
      "Portas de alumínio robustas, ideais para entradas, com design moderno e resistente",
    slug: "Sistemas-de-Construcao",
    pimg1: pimg2,
    location: "7 Lake Street, London",
    date: "15 Dec 2024",
  },
  {
    id: "3",
    title: "Esquadrias Omega",
    docomunt:
      "Perfis de alumínio para esquadrias reforçadas, com resistência e design inovador para grandes vãos",
    slug: "Tubos-Soldados",
    pimg1: pimg3,
    location: "7 Lake Street, London",
    date: "15 Dec 2024",
  },
  {
    id: "4",
    title: "Linha Temperado",
    docomunt:
      "Perfis de alumínio para estruturas de vidro temperado, usados em boxes e vitrines",
    slug: "Aluminio-Reciclado",
    pimg1: pimg4,
    location: "7 Lake Street, London",
    date: "15 Dec 2024",
  },
  {
    id: "5",
    title: "Fachadas, Portas e Cercas",
    docomunt:
      "Perfis de alumínio para fachadas, cercas e portões, oferecendo estética e segurança",
    slug: "Perfis-Extrudados",
    pimg1: pimg5,
    location: "7 Lake Street, London",
    date: "15 Dec 2024",
  },
  {
    id: "6",
    title: "Linha Industrial",
    docomunt:
      "Perfis de alumínio para aplicações industriais, incluindo estrutura de veículos e sistemas de ventilação",
    slug: "Tubos-de-Precisao",
    pimg1: pimg6,
    location: "7 Lake Street, London",
    date: "15 Dec 2024",
  },
];

const ProjectSection = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 2,
    lazyLoad: true,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1500, // Set the speed in milliseconds (e.g., 3000ms = 3s)
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className={"" + props.hclass}>
      <div className="container-fluid">
        <div className="project-slider">
          <Slider {...settings}>
            {Projects.slice(0, 5).map((project, pitem) => (
              <div className="project-card" key={pitem}>
                <div className="image">
                  <img src={project.pimg1} alt="" effect="blur" />
                </div>
                <div className="content">
                  {/* <h3><Link onClick={ClickHandler} to={`/project-single/${project.slug}`}>{project.title}</Link></h3> */}
                  <Title>{project.title}</Title>
                  <p>{project.docomunt}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};
export default ProjectSection;

const Title = styled.h3`
  color: white;
`;
