import React from 'react';
import  { HeroContent, HeroContainer } from './Hero.styles'
import Menu from "../Menu/Menu"
import { Link } from 'react-router-dom';
const Hero = () => {
  return (
    <HeroContainer>
      <Menu />
      <HeroContent>
        {/* Add any hero text or elements here */}
        <h1>Soluções Duráveis em Alumínio para sua Obra</h1>
                            <div className="about-btn btn-animation">
                                <Link  to="/produtos" className="theme-btn">Nossos produtos</Link>
                            </div>
      </HeroContent>
    </HeroContainer>
  );
};
// https://codepen.io/argyleink/pen/ExMgWLe
export default Hero;
