import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MenuButton onClick={() => setIsOpen(!isOpen)} className={isOpen ? "active" : ""}>
        <span></span>
        <span></span>
        <span></span>
      </MenuButton>
      <MenuList className={isOpen ? "show" : ""}>
        <li><Link to="/">Empresa</Link></li>
        <li><Link to="/produtos">Produtos</Link></li>
        <li><Link to="/catalogo">Catálogos</Link></li>
        <li><Link to="/blog">Blog</Link></li>
        <li><Link to="/sobre-nos">Sobre Nós</Link></li>
        <li><Link to="/contatos">Contatos</Link></li>
      </MenuList>
    </>
  );
};

// Styled Components
const MenuButton = styled.div`
  display: none;
  position: fixed;
  top: 5px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  @media (max-width: 912px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    position: absolute;
    background-color: var(--color-primary);
    height: 3px;
    width: 26px;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: center;

    &:nth-child(1) {
      transform: translateY(-8px);
    }
    &:nth-child(3) {
      transform: translateY(8px);
    }
  }

  &.active {
    background: rgba(19, 18, 18, 0.9);
    span:nth-child(1) {
      transform: rotate(45deg) translateY(0);
    }
    span:nth-child(2) {
      opacity: 0;
      transform: scaleX(0);
    }
    span:nth-child(3) {
      transform: rotate(-45deg) translateY(0);
    }
  }
`;

const MenuList = styled.ul`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background: rgba(19, 18, 18, 0.98);
  backdrop-filter: blur(10px);
  list-style: none;
  padding: 80px 30px;
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s ease;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  margin: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: -8px 0 24px rgba(0, 0, 0, 0.2);

  &.show {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  li {
    opacity: 0;
    transform: translateX(20px);
    transition: all 0.3s ease;
    margin-bottom: 25px;

    &:nth-child(1) { transition-delay: 0.1s; }
    &:nth-child(2) { transition-delay: 0.2s; }
    &:nth-child(3) { transition-delay: 0.3s; }
    &:nth-child(4) { transition-delay: 0.4s; }
    &:nth-child(5) { transition-delay: 0.5s; }
    &:nth-child(6) { transition-delay: 0.6s; }
  }

  &.show li {
    opacity: 1;
    transform: translateX(0);
  }

  a {
    color: var(--color-primary);
    font-family: var(--font-family-primary);
    font-size: 1.25rem;
    font-weight: 500;
    padding: 12px 0;
    display: block;
    position: relative;
    transition: all 0.3s ease;

    &::after {
      content: '';
      position: absolute;
      bottom: 8px;
      left: 0;
      width: 0;
      height: 2px;
      background: var(--color-primary);
      transition: width 0.3s ease;
    }

    &:hover {
      color: var(--color-primary);
      padding-left: 15px;

      &::after {
        width: 20px;
      }
    }
  }
`;


export default HamburgerMenu;
