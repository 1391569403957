import styled, { keyframes } from "styled-components";
import heroLarge from "../../assets/images/hero.jpg";

// Architectural-inspired animations
// Updated animations for a more dynamic feel
const parallaxShift = keyframes`
  0% {
    background-position: 50% 40%;
  }
  25% {
    background-position: 50% 60%;
  }
  50% {
    background-position: 50% 80%;
  }
  75% {
    background-position: 50% 60%;
  }
  100% {
    background-position: 50% 40%;
  }
`;

const fadeSlideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
export const HeroContainer = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.1)
      ),
      url(${heroLarge});
    background-size: cover;
    background-position: center;
    animation: ${parallaxShift} 50s ease-in-out infinite;
    z-index: 1;
  }
`;

export const HeroContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  text-align: left;
  color: white;
  padding: 2rem;
  position: relative;
  z-index: 2;
  & .btn-animation {
    animation: ${fadeSlideIn} 1.8s ease-out 1s forwards;
  }
  h1 {
    font-size: 2.65rem;
    font-weight: 100 !important;
    max-width: 650px;
    line-height: 1.3;
    animation: ${fadeSlideIn} 1.5s ease-out 0.5s forwards;
    position: relative;
    color: white;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
  }

  p {
    font-size: 1.2rem;
    max-width: 500px;
    line-height: 1.6;
    margin-bottom: 2rem;
    animation: ${fadeSlideIn} 1.5s ease-out 0.8s forwards;
    font-weight: 300;
    opacity: 0.9;
  }
`;

export const MenuWrapper = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.97);
  z-index: 10;
  position: relative;
  backdrop-filter: blur(2px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;
