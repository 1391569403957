import React from 'react';
import styled from 'styled-components';

const SectionContainer = styled.div``;

const Paragraph = styled.p`
  font-size: var(--font-size-lg);
  line-height: 1.8;
  color: var(--color-text-alt);
  margin: var(--spacing-medium) 0;
  font-weight: var(--font-weight-secondary-light);

  @media (min-width: 768px) {
    font-size: var(--font-size-xl);
  }
`;

const ImageContainer = styled.figure`
  margin: var(--spacing-large) 0;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
  display: block;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: scale(1.03);
  }
`;

const ImageCaption = styled.figcaption`
  padding: var(--spacing-small);
  font-size: var(--font-size-sm);
  color: var(--color-tertiary);
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the buttons on smaller screens */
  gap: var(--spacing-medium);
  margin: var(--spacing-large) 0;
`;

const DownloadLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-small);
  padding: var(--spacing-medium) var(--spacing-large);
  background-color: var(--color-secondary-2);
  color: var(--color-primary);
  border-radius: 50px;
  font-weight: var(--font-weight-primary-medium);
  transition: all 0.3s ease;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: var(--color-secondary-2-dark);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
  }

  &::before {
    content: '📄';
    font-size: 1.2em;
  }
`;

const ContentBlock = styled.div`
  margin-bottom: var(--spacing-large);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: var(--spacing-large);

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const SubCategoryGeneric = ({ content = [], pdfLink, categoryPdfLink, categoryName, subcategoryName }) => {
  return (
    <SectionContainer>
      {content.map((item, index) => (
        <ContentBlock key={index}>
          {item.paragraph && <Paragraph>{item.paragraph}</Paragraph>}
          {item.image && (
            <ImageContainer>
              <Image
                src={item.image}
                alt={item.alt || `Imagem ${index + 1}`}
              />
              {item.caption && <ImageCaption>{item.caption}</ImageCaption>}
            </ImageContainer>
          )}
        </ContentBlock>
      ))}

      <ButtonContainer>
        {pdfLink && (
          <DownloadLink href={pdfLink} download>
            Baixar PDF de {subcategoryName}
          </DownloadLink>
        )}

        {categoryPdfLink && (
          <DownloadLink href={categoryPdfLink} download>
            Baixar PDF de {categoryName}
          </DownloadLink>
        )}
      </ButtonContainer>
    </SectionContainer>
  );
};

export default SubCategoryGeneric;
