import styled from "styled-components";
import { Link } from "react-router-dom";
import Menu from "../Menu/Menu";

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: var(--color-primary);
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--color-secondary);
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: var(--color-tertiary);
  margin-bottom: 2rem;
`;

const StyledLink = styled(Link)`
  background-color: var(--color-secondary-2);
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none;
  transition: background 0.3s ease;

  &:hover {
    background-color: #c24a13;
  }
`;

function NotFound() {
  return (
    <>
      <Menu dark />
      <NotFoundWrapper>
        <Title>404 - Página não encontrada</Title>
        <Subtitle>A página que você procura não existe.</Subtitle>
        <StyledLink to="/">Voltar para a página inicial</StyledLink>
      </NotFoundWrapper>
    </>
  );
}

export default NotFound;
