import React from "react";
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom'
import hero4 from '../../assets/images/painel-solar.jpg'
import hero5 from '../../assets/images/contruccion-civil.jpg'
import hero6 from '../../assets/images/porta-aluminium3.jpeg'
import hero7 from '../../assets/images/variado.jpg';


const Hero4 = ({ hclass, scrollToProduct }) => {
    return (

        <section className={"" + hclass} >
            <Swiper
                // install Swiper modules
                modules={[Navigation, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                speed={1800}
                parallax={true}
                navigation
            >
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero4})` }}>
                        <div className="container-fluid">
                            <div className="slide-content">
                                <div className="slide-title">
                                <h2>Energia Solar</h2>
                                </div>
                                <div className="slide-text">
                                <p>Oferecemos perfis de alumínio de alta qualidade para projetos de energia solar, garantindo durabilidade e eficiência.</p>
                                </div>
                                <div  className="slide-btns">
                                    <button onClick={scrollToProduct} className="theme-btn">Mais informações</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero5})` }}>
                        <div className="container-fluid">
                            <div className="slide-content">
                                <div className="slide-title">
                                <h2>Construção Civil</h2>
                                </div>
                                <div className="slide-text">
                                <p>Perfis de alumínio sob medida para aplicações em construção civil, proporcionando resistência e versatilidade.</p>
                                </div>
                                <div className="slide-btns">
                                <button onClick={scrollToProduct} className="theme-btn">Mais informações</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero6})` }}>
                        <div className="container-fluid">
                            <div className="slide-content">
                                <div className="slide-title">
                                <h2>Portas de Alumínio</h2>
                                </div>
                                <div className="slide-text">
                                <p>Produzimos perfis de alumínio para portas, com design moderno e acabamento de alta precisão.</p>
                                </div>
                                <div className="slide-btns">
                                <button onClick={scrollToProduct} className="theme-btn">Mais informações</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero7})` }}>
                        <div className="container-fluid">
                            <div className="slide-content">
                                <div className="slide-title">
                                <h2>Perfis Genéricos</h2>
                                </div>
                                <div className="slide-text">
                                <p>Fornecemos perfis de alumínio para diversas aplicações industriais, com alta precisão e qualidade.</p>
                                </div>
                                <div className="slide-btns">
                                <button onClick={scrollToProduct} className="theme-btn">Mais informações</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                ...
            </Swiper>
        </section>
    )
}

export default Hero4;