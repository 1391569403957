import React from "react";
import styled from "styled-components";
import Menu from "../../components/Menu/Menu";
import ContactPage from "../../components/ContactUs/ContactUs";

const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background-color: #f4f4f4;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const ContactDetails = styled.div`
  width: 50%;
  padding: 30px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  h2 {
    margin-bottom: 1.8rem;
  }
  p{
    margin-bottom: .6rem;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const MapContainer = styled.div`
  width: 50%;
  height: 350px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContactUs = () => {
  return (
    <>
    <Menu dark/>

    <ContactPage/>
    </>
  );
};

export default ContactUs;