import "bootstrap/dist/css/bootstrap.min.css";
import "./css/font-awesome.min.css";
import "./css/themify-icons.css";
import "./css/animate.css";
import "./css/flaticon.css";
import "./sass/style.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ParallaxProvider } from "react-scroll-parallax";

// import { PersistGate } from "redux-persist/integration/react";
// import { store, persistor } from "./store/index";
// import { Provider } from "react-redux";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //   <Provider store={store}>
  // <PersistGate loading={null} persistor={persistor}>
  <ParallaxProvider>
    <App />
  </ParallaxProvider>
  // {/* </PersistGate> */}
  //{/* </Provider> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
