import React from "react";
import "./Contactpage.css"; // Import a CSS file for custom styles
import ContactForm from "./ContactForm";

const Contactpage = () => {
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            {/* Page Title */}
            <div className="page-title">
              <h1 className="mb-3">Entre em Contato Conosco</h1>
              <p className="mb-4">
                Estamos sempre à disposição para ajudar e responder às suas
                perguntas. Sinta-se à vontade para entrar em contato conosco
                através dos meios disponíveis abaixo.
              </p>
            </div>

            {/* Contact Details in Boxes */}
            <div className="office-info">
              <div className="row">
                {/* Address Section */}
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-placeholder"></i>
                      </div>
                    </div>
                    <div className="office-info-text smaller-font">
                      <h2>Endereço</h2>
                      <p>
                        R. Arcângelo João Grapiglia, 221 - Área Industrial,
                        Medianeira - PR, CEP: 85720610
                      </p>
                    </div>
                  </div>
                </div>

                {/* Email Section */}
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-email"></i>
                      </div>
                    </div>
                    <div className="office-info-text smaller-font">
                      <h2>E-mail</h2>
                      <p>atendimento@omegaaluminios.ind.br</p>
                    </div>
                  </div>
                </div>

                {/* Phone Section */}
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-phone-call"></i>
                      </div>
                    </div>
                    <div className="office-info-text smaller-font">
                      <h2>Telefone</h2>
                      <p>(45) 3264-3610</p>
                      <p>(45) 3264-1205</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Separated Working Hours Section */}
            <div className="working-hours-section">
              <h2>Horário de Funcionamento</h2>
              <p>08:00 às 19:00h</p>
              <p>
                Estamos abertos de segunda a sexta-feira, prontos para atendê-lo
                no horário comercial.
              </p>
            </div>

            {/* Contact Title Section */}
            <div className="wpo-contact-title">
              <h2>Tem alguma dúvida?</h2>
              <p>
                Entre em contato conosco para mais informações. Nossa equipe
                está pronta para ajudar com qualquer questão que você possa ter.
              </p>
            </div>
            <div className="wpo-contact-form-area">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>

      {/* Map Section */}
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map">
          <iframe
            title="mapa-de-contato"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.9826961722124!2d-54.05482102541472!3d-25.271167526624616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f1548339b0d125%3A0x9d194599bc6761c0!2sOmega%20Alum%C3%ADnios!5e0!3m2!1sen!2sbr!4v1742502681043!5m2!1sen!2sbr"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </section>
  );
};

export default Contactpage;
