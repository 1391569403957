import React from 'react'
import styled from 'styled-components';
import Menu from '../Menu/Menu';

const PrivacyContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-large);
  color: var(--color-secondary);

  /* Vertical rhythm system */
  & > * + * {
    margin-top: var(--spacing-large);
  }
`;

const Section = styled.section`
  & h1 {
    margin: 1.5rem auto;
    font-size: 2.5rem;
  }
  /* Consistent section spacing */
  & > * + * {
    margin-top: var(--spacing-medium);
  }
`;

const SectionTitle = styled.h2`
  color: var(--color-secondary-2);
  padding-bottom: var(--spacing-small);
  border-bottom: 2px solid var(--color-tertiary);
  margin-bottom: var(--spacing-medium) !important;
`;

const ContentText = styled.p`
  line-height: 1.8;
  margin: 0 0 var(--spacing-medium) 0;

  /* List spacing coherence */
  ul {
    margin: var(--spacing-small) 0 var(--spacing-medium) var(--spacing-large);
    padding: 0;
    
    li + li {
      margin-top: var(--spacing-small);
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  background: linear-gradient(90deg, transparent 0%, #ddd 50%, transparent 100%);
  margin: var(--spacing-large) 0;
`;

const OmegaPrivacyTerms = () => {
  return (
    <>
    <Menu dark/>
      <PrivacyContainer>
        <Section>
          <h1>Política de Privacidade e Termos de Uso - Omega Extrusão</h1>
          <ContentText>
            A <strong>Omega</strong>, especializada no ciclo completo de produção de perfis de alumínio - 
            da extrusão ao acabamento - fundada pelo Grupo Tempermed em 1996 em Medianeira (PR), 
            valoriza a transparência e segurança de seus clientes.
          </ContentText>
        </Section>

        <Divider />

        <Section>
          <SectionTitle>Política de Privacidade</SectionTitle>
          <ContentText>
            Coletamos apenas informações essenciais para:
            <ul>
              <li>Processamento de pedidos</li>
              <li>Contato com distribuidoras parceiras</li>
              <li>Melhoria contínua de nossos 300+ modelos de perfis</li>
            </ul>
          </ContentText>
          <ContentText>
            Seus dados nunca serão compartilhados com terceiros sem consentimento explícito.
          </ContentText>
        </Section>

        <Divider />

        <Section>
          <SectionTitle>Termos de Uso</SectionTitle>
          <ContentText>
            Ao utilizar nossos serviços, você concorda que:
            <ul>
              <li>Especificações técnicas dos perfis são propriedade intelectual Omega</li>
              <li>Orçamentos são válidos por 7 dias corridos</li>
              <li>Prazos de entrega seguem capacidade produtiva de nossa planta no Paraná</li>
            </ul>
          </ContentText>
        </Section>

        <Section>
          <SectionTitle>Nossa Estrutura</SectionTitle>
          <ContentText>
            Com sede em Medianeira-PR, operamos:
            <ul>
              <li>Linhas de extrusão de última geração</li>
              <li>Centro de acabamento próprio</li>
              <li>Logística integrada para todo território nacional</li>
            </ul>
          </ContentText>
        </Section>

        <Section>
          <ContentText>
            Para mais informações sobre nossos perfis padrão ou desenvolvimento de linhas exclusivas, 
            contate nosso departamento técnico.
          </ContentText>
          <ContentText>
            Última atualização: [Inserir data]
          </ContentText>
        </Section>
      </PrivacyContainer>
    </>
  );
};

export default OmegaPrivacyTerms;