import React from 'react';
import styled from 'styled-components';
import Menu from '../../components/Menu/Menu';
import img1 from '../../assets/images/work-with-us.jpg'


const Container = styled.div`
  color: white;
`;

const RecruitmentSection = styled.section`
  background: var(--color-secondary);
  padding: 40px;
  text-align: center;
`;

const Title = styled.h1`
  margin-bottom: 10px;
  font-size: 1rem;
  color: #d9d9d9;
`;

const Subtitle = styled.h2`
  color: gray;
  font-size: 1.8rem;
  letter-spacing: 5px;
  font-weight: 700;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  color: black;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextBlock = styled.div`
  flex: 1;
  padding: 60px;

  h2 {
    color: var(--color-text-alt);
    margin-bottom: 1rem;
  }

  p {
    color: black;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const ImageBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const WorkWithUs = () => {
  return (
    <Container>
      <Menu dark/>
      <RecruitmentSection>
        <Title>Junte-se à nossa equipe.</Title>
        <Subtitle>Recrutamento</Subtitle>
      </RecruitmentSection>
      <Content>
        <TextBlock>
          <h2>Estamos contratando!</h2>
          <p>A Omega é uma fábrica líder na transformação do alumínio bruto em soluções de alta qualidade para a construção civil e diversas indústrias.</p>
       <p>Procuramos profissionais motivados para fazer parte do nosso time. Se você quer crescer em um ambiente dinâmico e contribuir para a fabricação de produtos que moldam o futuro da construção e da indústria, venha trabalhar conosco!</p>
        <p>elissandro.soares@tempermed.com.br</p>
        </TextBlock>
        <ImageBlock>
          <Image src={img1} alt="Engenheira" />
        </ImageBlock>
      </Content>
      <Content>
       
      </Content>
    </Container>
  );
};

export default WorkWithUs;