import React from "react";
import styled from "styled-components";

const Navbar = () => {
  return (
    <Nav>
      <NavItem highlight href="/">Omega Alumínios</NavItem>
      <NavItem href="https://tempermed.com.br/home/" target="_blank" rel="noreferrer">Tempermed</NavItem>
      <NavItem>Grupo Onica</NavItem>
      <NavItem href="/trabalhe-conosco">Trabalhe Conosco</NavItem>
      <NavItem href="/contatos">Contatos</NavItem>
      <RequestQuote>
        <a href="https://api.whatsapp.com/send/?phone=554532643610&text&type=phone_number&app_absent=0">Solicite seu Orçamento</a>
      </RequestQuote>
    </Nav>
  );
};

const Nav = styled.nav`
  display: flex;
  width: 100%;
  height:2.8125rem;
  background: var(--color-secondary);
  align-items: center;
  justify-content: space-between;
  @media (max-width:760px) {
    display: none;
}
`;

const NavItem = styled.a`
  flex: 1;
  text-align: center;
  color: white;
  font-family: sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: .75rem;
  ${(props) =>
    props.highlight &&
    `
    background: gray;
  `}

    &:hover {
      background: white;
      color: black;
      transform: scale(1.05);
    }
`;

const RequestQuote = styled.div`
  flex: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & a {
    color:white;
    font-weight:100;
    font-size: 0.7rem;
    cursor: pointer;
    padding: 0.2rem .5rem;
    border: 2px solid white;
    transition: background 0.4s ease-in-out, color 0.4s ease-in-out, transform 0.3s ease;
    &:hover {
      background: white;
      color: black;
      transform: scale(1.05);
    }
  }
`;


export default Navbar;
