import React, { useEffect, useRef, useState } from "react";
import psingle1 from "../../assets/images/ab-3.jpeg";
import psingle2 from "../../assets/images/ab-4.jpeg";
import psingle3 from "../../assets/images/ab-5.jpeg";
import styled from "styled-components";
import Menu from "../Menu/Menu";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AboutUsPage = () => {
  const aboutRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the video is visible
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <Menu dark />
      <div className="wpo-project-single-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="wpo-project-single-wrap">
                <div className="wpo-project-single-item">
                  <div className="row align-items-center">
                    <div className="col-lg-7">
                      <div className="wpo-project-single-title">
                        <h3>Sobre a Omega</h3>
                      </div>
                      <p className="mb-2">
                        A Omega é uma empresa especializada em todo o ciclo de
                        produção de perfis de alumínio, abrangendo desde o
                        processo de extrusão até o acabamento final. Fundada em
                        1996 pelo Grupo Tempermed, em Medianeira, na região
                        oeste do Paraná, a Omega se consolidou como referência
                        no setor, com mais de 300 modelos de linha aberta, além
                        de uma extensa gama de modelos exclusivos, desenvolvidos
                        para atender com excelência as necessidades de
                        distribuidoras, construtoras e indústrias em todo o
                        Brasil seguindo as normas das leis de proteção de dados
                        e propriedade do nosso cliente para produtos
                        exclusivamente confidencial dos nossos clientes.
                      </p>
                      <p className="mb-2">
                        A empresa adota rigorosos padrões de qualidade em seus
                        processos, garantindo a conformidade com as mais
                        exigentes normas do setor. Entre as normas seguidas pela
                        Omega, destacam-se a QUALICOAT, que assegura a alta
                        qualidade no acabamento de superfícies de alumínio e a
                        ABNT NBR 8116, que regula os procedimentos de extrusão
                        de alumínio, oferecendo produtos com características
                        técnicas de resistência, durabilidade e desempenho
                        excepcionais com responsabilidade fornecemos o
                        certificado da QUALICOAT sobre os produtos que passam
                        pelo tratamento de superfície e pintura eletrostática e
                        o certificado de fabricação da Omega para todos os
                        produtos juntamente com o certificado de composição
                        química da matéria prima utilizada no processo.
                      </p>
                      <p className="mb-2">
                        Com foco em inovação e eficiência, a Omega se destaca
                        pela sua capacidade de fornecer soluções personalizadas,
                        adequadas às necessidades específicas de seus clientes,
                        mantendo sempre um compromisso com a qualidade e a
                        sustentabilidade ambiental.
                      </p>
                      <div className="about-btn mt-4 mb-4">
                        <button
                          className="theme-btn "
                          onClick={() =>
                            aboutRef.current?.scrollIntoView({
                              behavior: "smooth",
                            })
                          }
                        >
                          Saiba mais
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="wpo-project-single-content-des-right">
                        <ul>
                          <li>
                            Localização: <span>Medianeira, Paraná, Brasil</span>
                          </li>
                          <li>
                            Fundação: <span>1996</span>
                          </li>
                          <li>
                            Área industrial: <span>10.000 m²</span>
                          </li>
                          <li>
                            Especialização:{" "}
                            <span>Extrusão e acabamento de alumínio</span>
                          </li>
                          <li>
                            Equipamentos:{" "}
                            <span>
                              Três extrusoras (duas de 7" e uma de 5"), cabines
                              de pintura automatizadas, laboratórios,
                              ferramentaria, dois fornos de envelhecimento
                            </span>
                          </li>
                          <li>
                            Capacidade produtiva: <span>860t/mês</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="wpo-project-single-main-img">
                    <VideoWrapper ref={videoRef}>
                      {isVisible ? (
                        <video
                          src="/assets/video/insta-video.mp4"
                          autoPlay
                          loop
                          muted
                          playsInline
                        />
                      ) : (
                        <img
                          src="{placeholder}"
                          alt="Loading video..."
                          width="100%"
                        />
                      )}
                    </VideoWrapper>
                  </div>
                </div>
                <div
                  className="wpo-project-single-item list-widget"
                  ref={aboutRef}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="wpo-project-single-title">
                        <h3>Nossa Estratégia</h3>
                      </div>
                      <p>
                        A Omega se destaca pelo compromisso com qualidade e
                        inovação, investindo em tecnologia de ponta para
                        garantir produtos duráveis e eficientes.
                      </p>
                      <ul>
                        <li>Processo de extrusão e acabamento avançado.</li>
                        <li>Atendimento personalizado para cada cliente.</li>
                        <li>
                          Compromisso com a sustentabilidade e redução de
                          resíduos.
                        </li>
                        <li>Equipe altamente qualificada e experiente.</li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <div className="wpo-project-single-item-quote">
                        <p>
                          A Omega possui certificações{" "}
                          <UnderlinedLink
                            href="/assets/pdf/certificate.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            QUALICOAT
                          </UnderlinedLink>{" "}
                          e seguimos a norma ABNT NBR 8116, garantindo produtos
                          de alto padrão para o mercado.
                        </p>
                        <span>
                          Compromisso com a Qualidade -{" "}
                          <span>Equipe Omega</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wpo-project-single-gallery">
                  <div className="row mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <LazyLoadImage
                          src={psingle3}
                          alt="Fábrica"
                          effect="blur"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <LazyLoadImage
                          src={psingle2}
                          alt="Produção"
                          effect="blur"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wpo-project-single-item list-widget">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="wpo-project-single-title">
                        <h3>Resultados</h3>
                      </div>
                      <ul>
                        <li>Expansão contínua da linha de produtos.</li>
                        <li>
                          Parcerias sólidas com empresas nacionais e
                          internacionais.
                        </li>
                        <li>
                          Investimentos constantes em inovação e tecnologia.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 list-widget-s">
                      <div className="wpo-project-single-title">
                        <h3>Metas Futuras</h3>
                      </div>
                      <ul>
                        <li>Ampliação da capacidade produtiva.</li>
                        <li>Expansão da presença no mercado internacional.</li>
                        <li>
                          Desenvolvimento de novas tecnologias sustentáveis.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;

const UnderlinedLink = styled.a`
  color: inherit;
  text-decoration: none;
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: var(--color-secondary-2);
  }

  &:hover {
    color: var(--color-secondary-2-dark);
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  max-width: 950px; /* Adjust as needed */
  margin: 3rem auto 4rem;

  & video {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
  }

  & img {
    width: 100%;
    height: auto;
  }
`;
