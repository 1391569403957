import { NavLink } from "react-router-dom";
import styled from "styled-components";
import React from "react";
import HamburguerMenu from "../HamburguerMenu/HamburguerMenu";
import logo from "../../assets/images/logo-omega.png";
// import logoDark from "../../assets/images/logo-omega-dark.png";




const middelScree = "912px";
// Navbar with dynamic background based on "dark" prop
const Navbar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ dark }) => (dark ? "rgba(0, 0, 0, 0.9)" : "rgba(255, 255, 255, 0.05)")};
  padding: 0.2rem 1.5rem;
  position: sticky;
  top: 0;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  @media (max-width:${middelScree}) {
    padding: 0.4rem 1.5rem;
}

`;

const Logo = styled.div`
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  gap: 0.8rem;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }
  & a {
    display: flex;
  }
  img {
    height: 40px; /* Adjust size as needed */
    width: auto;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  gap: 1.5rem;
  list-style: none;
  margin: 0;
  padding: 0;


`;

const NavItem = styled.li`
  position: relative;
  padding: 0.5rem 0;

  a {
    font-weight: 500;
    color: ${({ dark }) => (dark ? "#ddd" : "white")};
    font-size: 0.8rem;
    position: relative;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 0.5rem 1rem;
    border-radius: 8px;
    text-decoration: none;

    &::before {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 2px;
      background: ${({ dark }) => (dark ? "#bbb" : "#2a2a2a")};
      transition: width 0.3s ease;
    }

    &:hover {
      color: ${({ dark }) => (dark ? "#fff" : "rgb(219, 199, 199)")} !important;
      background: ${({ dark }) => (dark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.03)")};

      &::before {
        width: 60%;
      }
    }

    &.active {
      color: ${({ dark }) => (dark ? "#bbb" : "var(--color-secondary-2)")};
      font-weight: 600;

      &::before {
        width: 80%;
        background: ${({ dark }) => (dark ? "#999" : "var(--color-tertiary)")};
      }
    }
  }
`;

const StyledLinkButton = styled(NavLink)`
  display: inline-block;
  background: ${({ dark }) => (dark ? "linear-gradient(45deg, #bbb, #777)" : "linear-gradient(45deg, #2a2a2a, #4a4a4a)")};
  color: ${({ dark }) => (dark ? "#000" : "white")};
  padding: 0.4rem 1rem;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  font-size:.8rem;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
  }
`;
const MiddleArea = styled.div`
display :flex;
justify-content:center;
align-items:center;
gap: 1.5rem;
@media (max-width:${middelScree}) {
    display: none;
}
`
const LanguageButton = styled.button`
  background: none;
  border: none;
  color: ${({ dark }) => (dark ? "#ddd" : "white")};
  font-size: 0.8rem;
  cursor: pointer;
  padding: 0.25rem .5rem;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-size:.7rem;

  &:hover {
    background: ${({ dark }) => (dark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.03)")};
  }

  &.active {
    background: ${({ dark }) => (dark ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.1)")};
  }
`;
const LanguageArea = styled.div`
@media (max-width:${middelScree}) {
    display: none;
}

`
const Menu = ({ dark }) => {
  return (
    <Navbar dark={dark}>
    <Logo>
      <NavLink to="/">
        <img src={ logo} alt="Omega Logo" />
      </NavLink>
    </Logo>
        <MiddleArea>
      <NavLinks>
        <NavItem dark={dark}><NavLink to="/">Empresa</NavLink></NavItem>
        <NavItem dark={dark}><NavLink to="/produtos">Produtos</NavLink></NavItem>
        <NavItem dark={dark}><NavLink to="/catalogo">Catálogos</NavLink></NavItem>
        {/* <NavItem dark={dark}><NavLink to="/blog">Blog</NavLink></NavItem> */}
      </NavLinks>
      <StyledLinkButton 
        dark={dark} 
        to="/sobre-nos"
        >
        Sobre Nós
      </StyledLinkButton>
      </MiddleArea>
      <LanguageArea>
        <LanguageButton dark={dark} className="active">PT</LanguageButton>
        <LanguageButton dark={dark}>ES</LanguageButton>
      </LanguageArea>
      <HamburguerMenu/>
    </Navbar>
  );
};

export default Menu;