import styled from "styled-components";
import {
  FaIndustry,
  FaCogs,
  FaCube,
  FaSeedling,
  FaRulerCombined,
  FaRegLightbulb,
} from "react-icons/fa";

const SegmentosContainer = styled.section`
  background: linear-gradient(45deg, var(--color-secondary) 0%, #1a1a1a 100%);
  padding: 120px 20px;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      transparent 95%,
      rgba(255, 255, 255, 0.03) 100%
    );
    background-size: 100% 8px;
    pointer-events: none;
  }
`;

const Title = styled.h2`
  font-weight: var(--font-weight-primary-medium);
  color: var(--color-primary);
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  position: relative;
  letter-spacing: 0.1em;
  text-align: center;
  font-size: 2rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const Card = styled.div`
  background: linear-gradient(145deg, #1e1e1e 0%, #2a2a2a 100%);
  border-radius: 20px;
  padding: 2.5rem;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px) scale(1.02);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);

    &::before {
      opacity: 1;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      45deg,
      transparent 20%,
      rgba(74, 144, 226, 0.1) 50%,
      transparent 80%
    );
    opacity: 0;
    transition: opacity 0.6s ease;
    pointer-events: none;
  }
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: var(--color-secondary-2);
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: rgba(74, 144, 226, 0.1);
  border-radius: 50%;
  transition: transform 0.3s ease;

  ${Card}:hover & {
    transform: rotate(15deg) scale(1.1);
  }
`;

const CardTitle = styled.h3`
  font-family: var(--font-family-primary);
  font-size: 0.9rem;
  font-weight: var(--font-weight-primary-medium);
  color: var(--color-primary);
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
`;

const CardText = styled.p`
  font-family: var(--font-family-secondary);
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.85);
  line-height: 1.6;
  max-width: 280px;
  margin: 0 auto;
`;

const Segmentos = () => {
  const segments = [
    {
      icon: <FaIndustry />,
      title: "Sistemas Arquitetônicos",
      text: "Alta resistência, durabilidade, design sofisticado e inovador",
    },
    {
      icon: <FaCogs />,
      title: "Soluções Industriais",
      text: "Componentes resistentes e eficientes para industria",
    },
    {
      icon: <FaCube />,
      title: "Soluções sob medida",
      text: "Produtos sob medida, adaptáveis a qualquer necessidade",
    },
    {
      icon: <FaSeedling />,
      title: "Inovações Sustentáveis",
      text: "Nossa matéria-prima pode ser reutilizada em outros projetos",
    },
    {
      icon: <FaRulerCombined />,
      title: "Engenharia de Precisão",
      text: "Projetos CAD/CAM e prototipagem rápida para aplicações críticas",
    },
    {
      icon: <FaRegLightbulb />,
      title: "Boas Práticas ESG",
      text: "Reciclagem e tratamento de resíduos para sustentabilidade",
    },
  ];

  return (
    <SegmentosContainer>
      <Title>
        <span>Nossos Segmentos</span>
      </Title>
      <Grid>
        {segments.map((segment, index) => (
          <Card key={index}>
            <IconWrapper>{segment.icon}</IconWrapper>
            <CardTitle>{segment.title}</CardTitle>
            <CardText>{segment.text}</CardText>
          </Card>
        ))}
      </Grid>
    </SegmentosContainer>
  );
};

export default Segmentos;
