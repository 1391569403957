import React from 'react';

import CategorySubcategoryViewer from '../CategorySubcategoryViewer/CategorySubcategoryViewer';

const ProductList = () => {
   return (
    <>
        <section className="wpo-shop-page section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <CategorySubcategoryViewer/>
                    </div>
                </div>
            </div>
        </section>
 </>
    );
};

export default ProductList;
