import { FaWhatsapp } from 'react-icons/fa';
import styled from 'styled-components';

const ContactSection = () => {
  return (
    <StyledSection>
      <ContentContainer>
        <TextContent>
          <h2>
            <span>Os produtos acima são exemplos do que fabricamos</span>
          </h2>
          <p>Entre em contato para discutir suas necessidades personalizadas e receber orientação especializada.</p>
          <WhatsAppButton href="https://wa.me/554532643610" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp className="icon" />
            <span>Chat via WhatsApp</span>
            <small>4532643610</small>
          </WhatsAppButton>
        </TextContent>
      </ContentContainer>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  min-height: 60dvh;
  display: flex;
  align-items: center;
  background-color: var(--color-secondary);
  color: var(--color-text);
  padding: var(--spacing-large) 0;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    min-height: auto;
    padding: var(--spacing-large) 0;
  }
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-medium);
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TextContent = styled.div`
  text-align: center;
  max-width: 800px;
  padding: var(--spacing-large);
  position: relative;

  h2 {
    color: var(--color-text);
    margin-bottom: var(--spacing-medium);
    font-weight: var(--font-weight-primary-medium);
    
    span::after {
      background: linear-gradient(90deg, transparent 0%, var(--color-secondary-2) 50%, transparent 100%) !important;
    }
  }

  p {
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-large);
    line-height: 1.6;
    color: rgba(255,255,255,0.9);
  }

  @media (max-width: 768px) {
    padding: var(--spacing-medium);
    
    p {
      font-size: var(--font-size-md);
    }
  }
`;

const WhatsAppButton = styled.a`
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-small);
  background: var(--color-secondary-2);
  color: var(--color-primary);
  padding: var(--spacing-medium) var(--spacing-large);
  border-radius: 50px;
  transition: all 0.3s ease;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  border: 2px solid transparent;

  .icon {
    font-size: 1.5rem;
    flex-shrink: 0;
  }

  span {
    font-family: var(--font-family-primary);
    font-weight: var(--font-weight-primary-medium);
    margin-right: var(--spacing-small);
  }

  small {
    font-size: 0.8em;
    opacity: 0.9;
  }

  &:hover {
    background: var(--color-secondary-2-dark);
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
  }

  &:active {
    transform: translateY(0);
  }

  @media (max-width: 480px) {
    flex-direction: column;
    padding: var(--spacing-medium);
    
    span, small {
      display: block;
      text-align: center;
    }
  }
`;

export default ContactSection;